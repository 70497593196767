import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import * as menu from "./modules/Menu/_redux/menuRedux";

const EslistPage = lazy(() => import("./modules/Eslist/Eslist"));

const ReportPage = lazy(() => import("./modules/Report/Report"));

const EsObjectPage = lazy(() => import("./modules/EsObject/EsObject"));

const WizardPage = lazy(() => import("./modules/Wizard/Wizard"));

const DashboardPage = lazy(() => import("./modules/Dashboard/Dashboard"));

const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"));


function BasePage({ defaultDashboardId, requestMenu }) {

    useEffect(() => {
        requestMenu();
    }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to={localStorage["lastVisitedPage"] ?? `/dashboard/${defaultDashboardId}`} />
                }
                <Route path="/eslist/:eslistId" component={EslistPage} />
                <Route path="/report/:reportId" component={ReportPage} />
                <Route path="/dashboard/:dashboardId" component={DashboardPage} />
                <Route path="/esobject/:esObjectId/:id/:operation" component={EsObjectPage} />
                <Route path="/wizard/:wizardId/:operation" component={WizardPage} />
                <Route path="/user-profile" component={UserProfilepage} />
                <Redirect to="/error/error-v1" />
            </Switch>
        </Suspense>
    );
}

export default connect(
    state => ({
        defaultDashboardId: state.auth.defaultDashboardId
    }),
    menu.actions)(BasePage);