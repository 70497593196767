import axios from "axios";

export const FORGOTPASSWORD_URL = '/api/account/forgotpassword';
export const CHECKRESETPASSWORDTOKEN_URL = '/api/account/checktoken';
export const RESETPASSWORD_URL = '/api/account/resetpassword';
export const CHANGEPASSWORD_URL = '/api/account/changepassword';
export const LOGIN_URL = '/api/account/login';
export const LOGIN_CONTENT_URL = "/api/account/logincontent";

export function login(email, password) {
    return axios.post(LOGIN_URL, { emailAddress: email, password });
}

export function getLoginContent() {
    return axios.get(LOGIN_CONTENT_URL);
}

export function requestPassword(emailAddress) {
    return axios.post(FORGOTPASSWORD_URL, { emailAddress });
}

export function resetPassword(passwordEnc, token) {
    return axios.post(RESETPASSWORD_URL, { token, passwordEnc });
}

export function checkToken(token) {
    return axios.post(CHECKRESETPASSWORDTOKEN_URL, { token });
}

export function changePassword(oldPasswordEnc, passwordEnc) {
    return axios.post(CHANGEPASSWORD_URL, { oldPasswordEnc, passwordEnc });
}