import { actions as authActions } from '../app/modules/Auth/_redux/authRedux';

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: { authToken }
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => {  return Promise.reject(err); }
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error?.response?.status == 401) {
                store.dispatch(authActions.logout());
            }
            return Promise.reject(error);
        }
    );
}

