import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { loadFields, saveLayout } from './reportCrud';
import { actionTypes as loginActionTypes } from '../../Auth/_redux/authRedux'

export const actionTypes = {
    ReportChanged: "[Report Changed] Action",
    ReportCleared: "[Report Cleared] Action",
    ReportDataLoaded: "[Report Data Loaded] Action",
    ReportLayoutChanged: "[Report Layout Changed] Action",
};

const initialReportState = {
    reportId: undefined,
    fields: undefined,
    layout: undefined,
};

export const reducer = persistReducer(
    { storage, key: "eurostoneWeb-report", whitelist: [] },
    (state = initialReportState, action) => {

        switch (action.type) {

            case actionTypes.ReportChanged: {
                const { reportId } = action.payload;
                return { ...initialReportState, reportId };
            }

            case actionTypes.ReportDataLoaded: {
                const { fields, layout } = action.payload;
                return { ...state, fields, layout: layout && JSON.parse(layout) };
            }

            case actionTypes.ReportLayoutChanged: {
                const { layout } = action.payload;
                return { ...state, layout };
            }

            case actionTypes.ReportCleared:
            case loginActionTypes.Logout: {
                return initialReportState;
            }

            default:
                return state;
        }
    }
);

export const actions = {
    changeReportId: (reportId) => ({ type: actionTypes.ReportChanged, payload: { reportId } }),
    clearReport: () => ({ type: actionTypes.ReportCleared }),
    setReportData: (reportData) => ({
        type: actionTypes.ReportDataLoaded, payload: reportData
    }),
    changeLayout: (reportData) => ({
        type: actionTypes.ReportLayoutChanged, payload: reportData
    })
};

export function* saga() {
    yield takeLatest(actionTypes.ReportChanged, function* reportChanged({ payload: { reportId } }) {
        const { response, error } = yield loadFields(reportId);
        if (response) {
            const { data: reportData } = response;
            yield put(actions.setReportData(reportData));
        } else {
            const { response: { data } } = error;
            console.log(data);
        }
    });

    yield takeLatest(actionTypes.ReportLayoutChanged, function* layoutChanged({ payload: { reportId, layout } }) {
        const { response, error } = yield saveLayout(reportId, layout);
        if (error) {
            console.log(error);
        }
    })
}
