import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { loadFields, saveLayout } from './wizardCrud';
import { actionTypes as loginActionTypes } from '../../Auth/_redux/authRedux'

export const actionTypes = {
    WizardChanged: "[Wizard Changed] Action",
    WizardCleared: "[Wizard Cleared] Action",
    WizardDataLoaded: "[Wizard Data Loaded] Action",
};

const initialWizardState = {
    wizardId: undefined,
    wizardCaption: undefined,
    fields: undefined,
    firstRenderTime: new Date().getTime()
};

export const reducer = persistReducer(
    { storage, key: "eurostoneWeb-wizard", whitelist: [] },
    (state = initialWizardState, action) => {

        switch (action.type) {

            case actionTypes.WizardChanged: {
                const { wizardId } = action.payload;
                return { ...initialWizardState, wizardId };
            }

            case actionTypes.WizardDataLoaded: {
                const { fields, operations, wizardCaption } = action.payload;
                const rows = [];

                const defaultValues = {};

                for (var i = 0; i < fields.length; i++) {
                    const field = fields[i];

                    defaultValues[field.wizardFieldName] = field.defaultValue;

                    if (!field.visible) {
                        continue;
                    }

                    if (field.minimumValue) {
                        try {
                            switch (field.dataType) {
                                case 'int': {
                                    field.minimumValue = parseInt(field.minimumValue);
                                    break;
                                }
                                case 'date': {
                                    field.minimumValue = new Date(field.minimumValue);
                                    break;
                                }
                            }
                        } catch (e) { field.minimumValue = null; }
                    }

                    switch (field.wizardFieldLocation) {
                        case 0: { //New row
                            rows.push([field]);
                            break;
                        }
                        case 1: { //Next to previous
                            const lastRow = rows[rows.length - 1];
                            lastRow.push(field);
                            break;
                        }
                    }
                }
                return { ...state, fields, rows, operations, defaultValues, wizardCaption, firstRenderTime: new Date().getTime() };
            }

            case actionTypes.WizardCleared:
            case loginActionTypes.Logout: {
                return initialWizardState;
            }

            default:
                return state;
        }
    }
);

export const actions = {
    changeWizardId: (wizardId) => ({ type: actionTypes.WizardChanged, payload: { wizardId } }),
    clearWizard: () => ({ type: actionTypes.WizardCleared }),
    setWizardData: (wizardData) => ({
        type: actionTypes.WizardDataLoaded, payload: wizardData
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.WizardChanged, function* wizardChanged({ payload: { wizardId } }) {
        const { response, error } = yield loadFields(wizardId);
        if (response) {
            const { data: wizardData } = response;
            yield put(actions.setWizardData(wizardData));
        } else {
            const { response: { data } } = error;
            console.log(data);
        }
    });
}
