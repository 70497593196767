import React, { useMemo, useState } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import {
    TextField,
} from "@material-ui/core";
import {
    makeStyles,
} from "@material-ui/core/styles";
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "calc(100% - 57px)"
    }
}));


export function AsideMenu({ disableScroll }) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            asideMenuAttr: uiService.getAttributes("aside_menu"),
            ulClasses: uiService.getClasses("aside_menu_nav", true),
            asideClassesFromConfig: uiService.getClasses("aside_menu", true)
        };
    }, [uiService]);

    const intl = useIntl();

    const classes = useStyles();

    const [filter, setFilter] = useState("");

    return (
        <>
            <TextField
                id="standard-name"
                label={intl.formatMessage({id:"MENU.FILTER"})}
                className={classes.textField}
                value={filter}
                onChange={e => setFilter(e.target.value)}
                margin="normal"
            />
            <div className="offcanvas-close mt-n1 pr-5" style={{ display: 'inline-block', marginBottom: 5, verticalAlign: 'bottom' }}>
                <a
                    className="btn btn-xs btn-icon btn-light btn-hover-warning"
                    title={intl.formatMessage({ id: "MENU.CLEARFILTER" })}
                    onClick={() => setFilter("")}
                >
                    <i className="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            {/* begin::Menu Container */}
            <div
                id="kt_aside_menu"
                data-menu-vertical="1"
                className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
                {...layoutProps.asideMenuAttr}
            >
                <AsideMenuList layoutProps={layoutProps} filter={filter} />
            </div>
            {/* end::Menu Container */}
        </>
    );
}
