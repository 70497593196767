/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout"
import Login from "./Login";
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { getLoginContent } from "../_redux/authCrud";

export function AuthPage() {

    const [loginContent, setLoginContent] = useState();

    useEffect(() => {
        getLoginContent()
            .then(({ data }) => {
                setLoginContent(data);
            })
            .catch(({ response }) => {
                console.log(response);
            });
    }, []);

    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                    id="kt_login"
                >
                    {/*begin::Aside*/}
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
                        }}
                    >
                        {/*begin: Aside Container*/}
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">

                            {/* start:: Aside content */}
                            <div className="flex-column-fluid d-flex flex-column justify-content-center text-white" dangerouslySetInnerHTML={{ __html: loginContent }}>
                            </div>
                            {/* end:: Aside content */}

                            {/* start:: Aside footer for desktop */}
                            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div className="opacity-70 font-weight-bold	text-white">
                                    &copy; {new Date().getFullYear()} Eurostone
                                </div>
                                {/*            <div className="d-flex">*/}
                                {/*                <Link to="/terms" className="text-white">*/}
                                {/*                    Privacy*/}
                                {/*</Link>*/}
                                {/*                <Link to="/terms" className="text-white ml-10">*/}
                                {/*                    Legal*/}
                                {/*</Link>*/}
                                {/*                <Link to="/terms" className="text-white ml-10">*/}
                                {/*                    Contact*/}
                                {/*</Link>*/}
                                {/*            </div>*/}
                            </div>
                            {/* end:: Aside footer for desktop */}
                        </div>
                        {/*end: Aside Container*/}
                    </div>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">

                        {/* begin::Content body */}
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                                <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                                <ContentRoute path="/auth/login" component={Login} />
                                <Redirect from="/auth" exact to="/auth/login" />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>
                        {/*end::Content body*/}

                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    );
}
