import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { loadOperation } from './esObjectCrud';
import { actionTypes as loginActionTypes } from '../../Auth/_redux/authRedux'

export const actionTypes = {
    EsObjectOperationChanged: "[EsObject Operation Changed] Action",
    EsObjectCleared: "[EsObject Cleared] Action",
    EsObjectDataLoaded: "[EsObject Data Loaded] Action",
};

const initialEsObjectState = {
    esObjectId: undefined,
    id: undefined,
    operation: undefined,
    html: undefined
};

export const reducer = persistReducer(
    { storage, key: "eurostoneWeb-esObject", whitelist: [] },
    (state = initialEsObjectState, action) => {

        switch (action.type) {

            case actionTypes.EsObjectOperationChanged: {
                const { esObjectId, id, operation } = action.payload;
                return { ...initialEsObjectState, esObjectId, id, operation };
            }

            case actionTypes.EsObjectDataLoaded: {
                const { html } = action.payload;
                return { ...state, html };
            }

            case actionTypes.EsObjectCleared:
            case loginActionTypes.Logout: {
                return initialEsObjectState;
            }

            default:
                return state;
        }
    }
);

export const actions = {
    changeEsObjectOperation: (esObjectId, id, operation) => ({ type: actionTypes.EsObjectOperationChanged, payload: { esObjectId, id, operation } }),
    clearEsObject: () => ({ type: actionTypes.EsObjectCleared }),
    setEsObjectData: (html) => ({
        type: actionTypes.EsObjectDataLoaded, payload: {html}
    })
};

export function* saga() {
    yield takeLatest(actionTypes.EsObjectOperationChanged, function* loadHtml({ payload: { esObjectId, id, operation } }) {
        const { response, error } = yield loadOperation(esObjectId,id,operation);
        if (response) {
            const { data: html } = response;
            yield put(actions.setEsObjectData(html));
        } else {
            const { response: { data } } = error;
            console.log(data);
        }
    });
}
