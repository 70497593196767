/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Tab, Nav } from "react-bootstrap";
import { AsideMenu } from "../../aside/aside-menu/AsideMenu";
import { useIntl } from 'react-intl';

export function QuickPanel() {
    const [selectedTab, setSelectedTab] = useState("Menu");

    const intl = useIntl();

    const setTab = _tabName => {
        setSelectedTab(_tabName);
    };

    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
            <Tab.Container
                defaultActiveKey={selectedTab}
            >
                {/*begin::Header*/}
                <div
                    className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                    <Nav
                        onSelect={setTab}
                        as="ul"
                        role="tablist"
                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
                    >
                        <Nav.Item as="li">
                            <Nav.Link
                                eventKey="Menu"
                                className={`nav-link ${selectedTab === "Menu" ? "active" : ""
                                    }`}
                            >
                                {intl.formatMessage({ id: "MENU.TITLE" })}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div className="offcanvas-close mt-n1 pr-5" style={{ position: "absolute", top: "15px", right: "10px" }}>
                        <a
                            href="#"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            id="kt_quick_panel_close"
                            title={intl.formatMessage({id:"MENU.CLOSEPANEL"})}
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                </div>
                {/*end::Header*/}

                {/*begin::Content*/}
                <div className="offcanvas-content px-10">
                    <div className="tab-content">
                        <div
                            id="kt_quick_panel_logs"
                            role="tabpanel"
                            className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Menu" ? "active show" : ""}`}
                        >
                            <AsideMenu />
                        </div>
                    </div>
                </div>
                {/*end::Content*/}
            </Tab.Container>
        </div>
    );
}
