import store from '../../redux/store';
import axios from 'axios';
import { showResponseMessage } from '../_helpers';
import Swal from 'sweetalert2';

export function downloadFile(url) {
    const authToken = encodeURIComponent(store.getState().auth.authToken);

    var link = document.createElement('a');
    link.href = `/api/file/downloadfile?url=${encodeURIComponent(url)}&authToken=${authToken}`;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
}

export function uploadFile(url, file) {

    var form = new FormData();
    form.append('file', file);

    axios.post(`/api/file/uploadfile?url=${encodeURIComponent(url)}`,
        form
    ).then(response => {
        console.log(response);
        if (response.data && response.data.length) {
            showResponseMessage(response.data);
        }
    }).catch(error => {
        console.log(error);
        if (error.response.data && error.response.data.message) {
            Swal.fire({
                title: error.response.data.message,
                text: error.response.data.messageEN,
                icon: 'error'
            })
        }
    });

}