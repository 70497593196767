export function parseQueryString(str) {

    const objURL = {};

    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
};

export function setLastVisitedPage() {
    localStorage["lastVisitedPage"] = `${window.location.pathname}${window.location.search}`;
}