import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { loadMenu } from './menuCrud';
import { actionTypes as loginActionTypes } from '../../Auth/_redux/authRedux'

export const actionTypes = {
    MenuRequested: "[Request Menu] Action",
    LoadMenu: "[Load Menu] Action",
    SetBreadcrumbs: "[Set Breadcrumbs] Action",
};

const initialMenuState = {
    menuTree: undefined,
    breadcrumbs: undefined,
    title: undefined,
};

export const reducer = persistReducer(
    { storage, key: "eurostoneWeb-menu", whitelist: ["menuTree"] },
    (state = initialMenuState, action) => {
        switch (action.type) {

            case actionTypes.LoadMenu: {
                let { menuTree } = action.payload;
                fillNodeUrls(menuTree);
                return { ...state, menuTree };
            }

            case actionTypes.SetBreadcrumbs: {
                const { breadcrumbs } = action.payload;
                let title = undefined;
                if (breadcrumbs && breadcrumbs.length) {
                    title = breadcrumbs[breadcrumbs.length - 1].caption
                }
                return { ...state, breadcrumbs, title };
            }

            case loginActionTypes.Logout: {
                return initialMenuState;
            }


            default:
                return state;
        }
    }
);

const properties = ['esList', 'dashboard', 'report'];
const fillNodeUrls = (nodeList) => {
    nodeList.forEach(node => {
        let mainPath = undefined;
        let id = undefined;

        properties.forEach(prop => {
            if (node[`${prop}Id`]) {
                mainPath = prop.toLowerCase();
                id = node[`${prop}Id`];
            }
        });

        if (mainPath && id) {
            node.url = `/${mainPath}/${id}`;
        } else if (node.externalUrl) {
            node.url = node.externalUrl;
            node.isExternal = true;
        }

        fillNodeUrls(node.subNodes);
    })
}

export const actions = {
    requestMenu: (menuTree) => ({ type: actionTypes.MenuRequested, payload: { menuTree } }),
    loadMenu: (menuTree) => ({
        type: actionTypes.LoadMenu, payload: { menuTree }
    }),
    setBreadcrumbs: (breadcrumbs) => ({
        type: actionTypes.SetBreadcrumbs, payload: { breadcrumbs }
    })
};

export function* saga() {
    yield takeLatest(actionTypes.MenuRequested, function* menuRequested() {
        const { response, error } = yield loadMenu();
        if (response) {
            const { data: menuTree } = response
            yield put(actions.loadMenu(menuTree));
        } else {
            const { response: { data } } = error;
            console.log(data);
        }
    });
}
