import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetPassword, checkToken } from "../_redux/authCrud";
import { parseQueryString } from '../../../../_metronic/_helpers';

const initialValues = {
    password: "",
    confirmPassword: "",
};

function ForgotPassword(props) {
    const token = parseQueryString(window.location.search)["token"];

    const [tokenState, setTokenState] = useState({ checked: false, valid: false, message: null });

    useEffect(() => {
        checkToken(token)
            .then(() => {
                setTokenState({ checked: true, valid: true, message: null });
            })
            .catch((error) => {
                setTokenState({
                    checked: true,
                    message: error?.response?.data?.message ?? "ERROR",
                    valid: false
                });
            });
    }, [token]);

    const { intl } = props;
    const ForgotPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD",
                })
            )
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORDSTRENGTH" })),
        confirmPassword: Yup.string()
            .required(
                intl.formatMessage({
                    id: "VALIDATION.REQUIRED_FIELD",
                })
            )
            .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORDSNOTMACH" }))
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (tokenState.valid) {
                resetPassword(values.password, token)
                    .then(({ data }) => {
                        setSubmitting(false);
                        setStatus({
                            message: intl.formatMessage({ id: "AUTH.RESET.SUCCESS" }),
                            success: true
                        });
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        setStatus({
                            message: error?.response?.data?.message ?? "ERROR",
                            success: false
                        });
                    });
            }
        },
    });

    if (tokenState.checked && !tokenState.valid && !formik.status?.invalidated) {
        formik.setStatus({
            message: tokenState.message,
            success: false,
            invalidated: true
        });
    }

    return (
        <>
            <div className="login-form login-forgot" style={{ display: "block" }}>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.RESET.TITLE" />
                    </h3>
                    <div className="text-muted font-weight-bold">
                        <FormattedMessage id="AUTH.RESET.DESC" />
                    </div>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                    {formik.status && (
                        <div className={`mb-10 alert alert-custom alert-light-${formik.status.success ? 'success' : 'danger'} alert-dismissible`}>
                            <div className="alert-text font-weight-bold">
                                {formik.status.message}
                            </div>
                        </div>
                    )}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            type="password"
                            placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            type="password"
                            placeholder={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "confirmPassword"
                            )}`}
                            name="confirmPassword"
                            {...formik.getFieldProps("confirmPassword")}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap flex-center">
                        <button
                            id="kt_login_forgot_submit"
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            disabled={formik.isSubmitting || !tokenState.valid}
                        >
                            <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                        </button>
                        <Link to="/auth">
                            <button
                                type="button"
                                id="kt_login_forgot_cancel"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
