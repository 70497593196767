/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
    getBreadcrumbsAndTitle,
    useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from 'react-intl';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../_helpers";
import { QuickUserToggler } from "../extras/QuiclUserToggler";


export function SubHeader() {
    const { breadcrumbs, title } = useSelector((state) => state.menu, shallowEqual);

    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();
    const intl = useIntl();

    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(
                uiService.config,
                "subheader.mobile-toggle"
            ),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses(
                "subheader_container",
                true
            ),
        };
    }, [uiService]);

    //useLayoutEffect(() => {
    //    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    //    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    //    //const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    //    //subheader.setBreadcrumbs(breadcrumbs);
    //    //subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    //    // eslint-disable-next-line
    //}, [location.pathname]);

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    useEffect(() => {
    }, [subheader]);

    return (
        <div
            id="kt_subheader"
            className={`subheader py-2 py-lg-12   ${layoutProps.subheaderCssClasses}`}
        >
            <div
                className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
            >
                {/* Info */}
                <div className="d-flex align-items-center flex-wrap mr-1">
                    {/* begin::Mobile Toggle */}
                    {layoutProps.subheaderMobileToggle && (
                        <button
                            className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span />
                        </button>
                    )}
                    {/* end::Mobile Toggle */}

                    {/* begin::Heading */}
                    <div className="d-flex flex-column">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="quick-panel-tooltip">{intl.formatMessage({ id: "MENU.TITLE" })}</Tooltip>}
                        >
                            <div
                                className="topbar-item"
                                data-toggle="tooltip"
                                title={intl.formatMessage({ id: "MENU.TITLE" })}
                                data-placement="right"
                            >
                                <div
                                    className="btn btn-hover-transparent-white btn-lg mr-1"
                                    id="kt_quick_panel_toggle"
                                >
                                    <span className="svg-icon svg-icon-xl mr-1">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Layout/Layout-4-blocks.svg"
                                            )}
                                            title={intl.formatMessage({ id: "MENU.TITLE" })}
                                        />
                                    </span>
                                </div>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className="d-flex flex-column">

                        {/* begin::Title */}
                        <h2 className="text-white font-weight-bold my-2 mr-5">
                            {title}
                        </h2>
                        {/* end::Title */}

                        {/*<BreadCrumbs items={breadcrumbs} />*/}
                    </div>
                    {/* end::Heading */}
                </div>

                {/* Toolbar */}
                <div className="d-flex align-items-center">
                    {/*<QuickActions />*/}
                    <QuickUserToggler />
                </div>
            </div>
        </div>
    );
}
