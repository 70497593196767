import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/hu";

//import DxLocalization from 'devextreme/localization';
import { loadMessages, locale } from 'devextreme/localization';
import { ResourceManager } from 'devexpress-dashboard';

import dxAnalyticsHU from './dxMessages/dx-analytics-core.hu';
import dxDashboardHU from './dxMessages/dx-dashboard.hu';
import dxEn from "./dxMessages/en";
import dxHu from "./dxMessages/hu";

import enMessages from "./messages/en";
import huMessages from "./messages/hu";


const allMessages = {
    en: enMessages,
    hu: huMessages
};

const dxMessages = {
    en: dxEn,
    hu: dxHu
}

const dxDashboardMessages = {
    hu: Object.assign({}, dxDashboardHU, dxAnalyticsHU)
};

let intl = null;

export function I18nProvider({ children }) {
    const currentLocale = useLang();
    const messages = allMessages[currentLocale];

    ResourceManager.setLocalizationMessages(dxDashboardMessages[currentLocale]);
    loadMessages(dxMessages);
    locale(currentLocale);

    intl = new IntlProvider({ locale: currentLocale, messages }).state.intl;

    return (
        <IntlProvider locale={currentLocale} messages={messages}>
            {children}
        </IntlProvider>
    );
}

export const getIntl = () => intl