import { setLastVisitedPage } from "./UrlHelper";

export function checkIsActiveByPath(node, pathParams, idField = undefined) {
    const { mainPath, subPath } = pathParams;

    if (!mainPath) {
        return false;
    }

    if (!idField) {
        switch (mainPath.toLowerCase()) {
            case 'eslist': idField = 'esListId'; break;
            case 'dashboard': idField = 'dashboardId'; break;
            case 'report': idField = 'reportId'; break;
        }
    }

    if (idField && subPath && node[idField] && node[idField] == subPath) {
        return true;
    }

    if (node.subNodes &&
        node.subNodes.length &&
        node.subNodes.some(subNode => checkIsActiveByPath(subNode, pathParams, idField))) {
        return true;
    }

    return false;
}

export function createBreadcrumbs(field, value, menuTree) {
    setLastVisitedPage();

    if (field && value && menuTree) {
        const getNodes = (result, object) => {
            if (object[field] && object[field] == value) {
                result.push(object);
                return result;
            }
            if (Array.isArray(object.subNodes)) {
                const subNodes = object.subNodes.reduce(getNodes, []);
                if (subNodes.length) result.push({ ...object, subNodes });
            }
            return result;
        };

        const breadcrumbs = menuTree.reduce(getNodes, []);

        const flattened = [];
        if (breadcrumbs && breadcrumbs.length) {
            let current = breadcrumbs;
            while (current && current.length) {
                flattened.push(current[0]);
                current = current[0].subNodes;
            }
        }

        return flattened;
    }
}