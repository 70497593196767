import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { createStore } from 'devextreme-aspnet-data';
import DataGrid,
{
    FilterBuilder,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Sorting,
    StateStoring,
    ColumnChooser
} from 'devextreme-react/data-grid';
import { useIntl } from 'react-intl';


function Grid({ name, dataUrls: { key = 'id', loadUrl, updateUrl, updateEndpoint, updateMethod = 'POST' }, children, buttons, gridProps, layout: { loadLayout, saveLayout, resetLayout } }) {

    const [visible, setVisible] = useState(true);

    const authToken = useSelector((state) => state.auth.authToken, shallowEqual);

    useEffect(() => {
        if (!visible) {
            setVisible(true);
        }
    });

    const { formatMessage } = useIntl();

    const dataSource = createStore({
        key,
        loadUrl,
        updateUrl,
        updateMethod,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.headers = { "Authorization": `Bearer ${authToken}` };
            if (method === 'update') {
                ajaxOptions.data['url'] = updateEndpoint;
            }
        },
        onAjaxError: e => {
            if (e.xhr.response) {
                let json = null;
                try {
                    json = JSON.parse(e.xhr.response);
                } catch (e) { }
                console.log(json);
                if (json) {
                    e.error = `${json.message} - ${json.messageEN}`;
                }
            }
        }
    });

    const innerResetLayout = () => {
        saveLayout({});
        if (resetLayout && typeof resetLayout == 'function') {
            resetLayout();
        }
        else {
            setVisible(false);
        }
    }

    const onToolBarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    hint: formatMessage({ id: "DATAGRID.RESETLAYOUT" }),
                    text: formatMessage({ id: "DATAGRID.RESETLAYOUT" }),
                    onClick: innerResetLayout,
                }
            },
        );
        if (buttons && buttons.length) {
            buttons.forEach(button => {
                e.toolbarOptions.items.unshift(
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: button
                    }
                );
            });
        }

        e.toolbarOptions.items.forEach(item => item.showText = 'always');
    };

    return (
        <>
            {visible &&
                <DataGrid
                    id={name}
                    dataSource={dataSource}
                    allowColumnResizing
                    allowColumnReordering
                    columnAutoWidth
                    columnResizingMode="widget"
                    remoteOperations
                    rowAlternationEnabled
                    showBorders
                    width="100%"
                    onToolbarPreparing={onToolBarPreparing}
                    {...gridProps}
                >
                    <ColumnChooser enabled />
                    <HeaderFilter visible allowSearch />
                    <FilterRow visible />
                    <Sorting mode="multiple" />

                    {children}

                    <FilterPanel visible />
                    <FilterBuilder />
                    <FilterBuilderPopup position={filterBuilderPopupPosition} />
                    <Pager
                        allowedPageSizes={[10, 25, 50, 100]}
                        showPageSizeSelector
                    />
                    <Paging defaultPageSize={10} />
                    <StateStoring
                        enabled
                        savingTimeout={500}
                        type="custom"
                        customSave={saveLayout}
                        customLoad={loadLayout}
                    />
                </DataGrid>}
        </>
    );
}

const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 }
};

export { Grid };