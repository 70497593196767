import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { loadFields, saveLayout } from './eslistCrud';
import { actionTypes as loginActionTypes } from '../../Auth/_redux/authRedux'

export const actionTypes = {
    EslistChanged: "[Eslist Changed] Action",
    EslistCleared: "[Eslist Cleared] Action",
    EslistDataLoaded: "[Eslist Data Loaded] Action",
    EslistLayoutChanged: "[Eslist Layout Changed] Action",
};

const initialEslistState = {
    eslistId: undefined,
    fields: undefined,
    layout: undefined,
    esObjectId: undefined,
};

export const reducer = persistReducer(
    { storage, key: "eurostoneWeb-eslist", whitelist: [] },
    (state = initialEslistState, action) => {

        switch (action.type) {

            case actionTypes.EslistChanged: {
                const { eslistId } = action.payload;
                return { ...initialEslistState, eslistId };
            }

            case actionTypes.EslistDataLoaded: {
                const { fields, layout, esObjectId, updateUrl, operations } = action.payload;
                return { ...state, fields, esObjectId, updateUrl, layout: layout && JSON.parse(layout), operations };
            }

            case actionTypes.EslistLayoutChanged: {
                const { layout } = action.payload;
                return { ...state, layout };
            }

            case actionTypes.EslistCleared:
            case loginActionTypes.Logout: {
                return initialEslistState;
            }

            default:
                return state;
        }
    }
);

export const actions = {
    changeEslistId: (eslistId) => ({ type: actionTypes.EslistChanged, payload: { eslistId } }),
    clearEslist: () => ({ type: actionTypes.EslistCleared }),
    setEslistData: (eslistData) => ({
        type: actionTypes.EslistDataLoaded, payload: eslistData
    }),
    changeLayout: (eslistData) => ({
        type: actionTypes.EslistLayoutChanged, payload: eslistData
    })
};

export function* saga() {
    yield takeLatest(actionTypes.EslistChanged, function* eslistChanged({ payload: { eslistId } }) {
        const { response, error } = yield loadFields(eslistId);
        if (response) {
            const { data: eslistData } = response;
            yield put(actions.setEslistData(eslistData));
        } else {
            const { response: { data } } = error;
            console.log(data);
        }
    });

    yield takeLatest(actionTypes.EslistLayoutChanged, function* layoutChanged({ payload: { eslistId, layout } }) {
        const { response, error } = yield saveLayout(eslistId, layout);
        if (error) {
            console.log(error);
        }
    })
}
