import Swal from 'sweetalert2';
import { getIntl } from '../i18n/I18nProvider';

export function showResponseMessage(data) {
    var { formatMessage } = getIntl();

    var html = `<div style="max-height: 300px; overflow: auto;">
                    ${data.map(row => `<div class="alert alert-${(row.success ? 'success' : 'warning')}"> ${row.message} </div>`)}
                </div>`;

    return Swal.fire({
        title: formatMessage({ id: "GLOBAL.RESPONSEMESSAGETITLE" }),
        icon: 'info',
        html
    });
}