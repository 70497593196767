import axios from "axios";

export function loadFields(eslistId) {
    return axios.get(`/api/eslist/getfields/${eslistId}`)
        .then(response => ({ response }))
        .catch(error => ({ error }));
}

export function saveLayout(eslistId, layout) {
    var data = new FormData();
    data.append('layout', JSON.stringify(layout));

    return axios.post(`/api/eslist/savelayout/${eslistId}`, data)
        .then(response => ({ response }))
        .catch(error => ({ error }));
}