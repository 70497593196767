/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useIntl } from 'react-intl';
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuList({ layoutProps, filter }) {
    const intl = useIntl();

    const { menuTree } = useSelector((state) => state.menu, shallowEqual);
    var filteredMenu = menuTree;

    if (menuTree && filter) {
        filteredMenu = filterMenu(menuTree, filter);
    }

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {filteredMenu && filteredMenu.length && filteredMenu.map((node, ix) => (<AsideMenuItem node={node} key={ix} isFiltering={filter !== ""} />)) || intl.formatMessage({ id: "MENU.NORESULT"})}
            </ul>
            {/* end::Menu Nav */}
        </>
    );
}

function filterMenu(menuTree, filter) {
    const getNodes = (result, object) => {
        if (object.caption && object.caption.toLowerCase().includes(filter.toLowerCase())) {
            result.push(object);
            return result;
        }
        if (Array.isArray(object.subNodes)) {
            const subNodes = object.subNodes.reduce(getNodes, []);
            if (subNodes.length) result.push({ ...object, subNodes });
        }
        return result;
    };

    return menuTree.reduce(getNodes, []);
}