import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as menu from "../app/modules/Menu/_redux/menuRedux";
import * as eslist from "../app/modules/Eslist/_redux/eslistRedux";
import * as report from "../app/modules/Report/_redux/reportRedux";
import * as esObject from "../app/modules/EsObject/_redux/esObjectRedux";
import * as wizard from "../app/modules/Wizard/_redux/wizardRedux";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    menu: menu.reducer,
    eslist: eslist.reducer,
    esObject: esObject.reducer,
    report: report.reducer,
    wizard: wizard.reducer,
});

export function* rootSaga() {
    yield all([auth.saga(), menu.saga(), eslist.saga(), esObject.saga(), report.saga(), wizard.saga()]);
}
