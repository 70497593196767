import React from 'react';
import { NavLink, useParams } from "react-router-dom";
import { checkIsActiveByPath } from "../../../../_helpers";
import $ from 'jquery';

export function AsideMenuItem({ node, isFiltering }) {
    const pathParams = useParams();
    const getMenuItemActive = (node, hasSubmenu = false) => {
        return checkIsActiveByPath(node, pathParams)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : hasSubmenu && isFiltering && "menu-item-open";
    };

    if (node.subNodes && node.subNodes.length) {
        return (
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(node, true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
                <a className="menu-link menu-toggle">
                    <span className="menu-text">{node.caption}</span>
                    <i className="menu-arrow" />
                </a>
                <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                        <li className="menu-item  menu-item-parent" aria-haspopup="true">
                            <span className="menu-link">
                                <span className="menu-text">{node.caption}</span>
                            </span>
                        </li>
                        {node.subNodes.map((el, ix) => { return (<AsideMenuItem node={el} key={ix} isFiltering={isFiltering} />); })}
                    </ul>
                </div>
            </li>
        );
    } else {
        return (
            <li
                className={`menu-item ${getMenuItemActive(node, false)}`}
                aria-haspopup="true"
            >
                {node.isExternal ?
                    <a className="menu-link" href={node.url ?? ''} target="_blank" onClick={e => { $('#kt_quick_panel_close')[0].click();} }>
                        <span className="menu-text">{node.caption}</span>
                    </a>
                    :
                    <NavLink className="menu-link" to={node.url ?? ''} onClick={e => { $('#kt_quick_panel_close')[0].click(); }}>
                        <span className="menu-text">{node.caption}</span>
                    </NavLink>
                }
            </li>
        );
    }
}